.header > img {
  /* position: absolute; */
  width: 50px;
  height: 50px;
  float: right;
  margin-top: -40px;
  cursor: pointer;
}

.header > p {
  /* position: absolute; */
}
